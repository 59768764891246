import * as TYPES from "./types";
import router from "../../../router";

export default {
    login: async (context, { service: AuthService }) => {
        try{
            context.commit(TYPES.LOGIN_START);
            const res = await AuthService.login();
            // const permissions = await PermissionsService.getAll();
            context.commit(TYPES.LOGIN_SUCCESS, res.data.data);
            router.push({ name: "contacts" });
        }catch(e){
            context.commit(TYPES.LOGIN_FAILURE, null);
        }
    },
    logout: async (context, { service: AuthService }) => {
        try {
            await AuthService.logout({toastAble: true});
            localStorage.removeItem("token");
            context.commit(TYPES.LOGOUT);
            router.push({ name: "login" });
        }catch(e){
            console.log(e);
        }
        
    },
    fetchUser: async (context, { service: AuthService }) => {
        try {
            const res = await AuthService.fetchUser({
                params: { include: "permissions" },
            });
            context.commit(TYPES.FETCH_USER_SUCCESS, res.data.data);
        } catch (e) {
            console.log(e);
            context.commit(TYPES.FETCH_USER_FAILURE, {data: null});
        }
    },
};
