<template>
    <div class="layout-topbar">
        <router-link
            to="/"
            class="layout-topbar-logo">
            <img
                class="logo-img"
                alt="Logo"
                :src="logo" >
        </router-link>
        <button
            class="p-link layout-menu-button layout-topbar-button"
            @click="onMenuToggle"
        >
            <i class="pi pi-bars"/>
        </button>

        <button
            v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'scalein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'fadeout',
                hideOnOutsideClick: true,
            }"
            class="p-link layout-topbar-menu-button layout-topbar-button"
        >
            <i class="pi pi-ellipsis-v"/>
        </button>
        <ul class="layout-topbar-menu hidden lg:flex origin-top">
            <li>
                <button class="p-link layout-topbar-button">
                    <i class="pi pi-calendar"/>
                    <span>Events</span>
                </button>
            </li>
            <li>
                <button class="p-link layout-topbar-button">
                    <i class="pi pi-cog"/>
                    <span>Settings</span>
                </button>
            </li>
            <li>
                <Menu
                    ref="usermenu"
                    :model="userMenuItems"
                    :popup="true" />
                <Button
                    class="p-link layout-topbar-button"
                    @click="toggleUserMenu"
                >
                    <i class="pi pi-user"/>
                    <span>Profile</span>
                </Button>
            </li>
        </ul>
    </div>
</template>

<script>
import AuthService from "./service/AuthService";

export default {
    data() {
        return {
            userMenuItems: [
                {
                    label: "Sign Out",
                    icon: "pi pi-sign-out",
                    command: () => {
                        this.logout();
                    },
                },
            ],
            service: new AuthService(),
            s3_url: process.env.VUE_APP_S3_URL,
            logo: require("./assets/img/app-logo.png")
        };
    },
    methods: {
        onMenuToggle(event) {
            this.$emit("menu-toggle", event);
        },
        onTopbarMenuToggle(event) {
            this.$emit("topbar-menu-toggle", event);
        },
        topbarImage() {
            return this.s3_url + "public-uploads/10xinvestlogo.jpg";
        },
        toggleUserMenu(event) {
            this.$refs.usermenu.toggle(event);
        },
        logout() {
            this.$store.dispatch("auth/logout", { service: this.service });
        },
    }
};
</script>