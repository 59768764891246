import authModule from "./modules/auth";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

const vuexLocalStorage = new VuexPersist({
    key: "vuex", // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    reducer: (state) => ({auth: {user: state.auth.user}}) // only persist user data
});

export default new Vuex.Store({
    modules: {
        auth: authModule,
    },
    plugins: [vuexLocalStorage.plugin]
});
