
export const Permissions = {
    VIEW_CONTACTS: "view-contacts",
    VIEW_INVESTING_ENTITES: "view-investing-entites",
    VIEW_DISTRIBUTIONS: "view-distributions",
    VIEW_INVESTMENTS: "view-investments",
    VIEW_PAYMENT_ACCOUNTS: "view-payment-accounts",
    VIEW_USERS: "view-users",
    VIEW_LOGS: "view-logs",
    VIEW_VIEWS: "view-views"
};

export const INPUT_TYPES = {
    TEXT: "text",
    EMAIL: "email",
    MULTI_SELECT: "multiselect",
};

export const ROLES = {
    ADMIN: "admin",
    MANAGER: "manager",
};
