<template>
    <div
        :class="containerClass"
        @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div
            class="layout-sidebar"
            @click="onSidebarClick">
            <AppMenu
                :model="menu"
                @menuitem-click="onMenuItemClick" />
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <div>
                    <Breadcrumb
                        :home="home"
                        :model="$router.currentRoute._value.meta.breadcrumb"
                    />
                </div>
                <router-view />
            </div>
        </div>

        <AppConfig
            :layoutMode="layoutMode"
            @layout-change="onLayoutChange" />
        <transition name="layout-mask">
            <div
                v-if="mobileMenuActive"
                class="layout-mask p-component-overlay"
            />
        </transition>
    </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import { Permissions } from "./shared/constants";

export default {
    emits: ["change-theme"],
    data() {
        return {
            layoutMode: "overlay",
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            s3_url: process.env.VUE_APP_S3_URL,
            menu: [
                {
                    label: "Pages",
                    items: [
                        {
                            label: "Contacts",
                            icon: "pi pi-fw pi-users",
                            to: "/",
                            permissions: [Permissions.VIEW_CONTACTS],
                        },
                        {
                            label: "Investing Entities",
                            icon: "pi pi-fw pi-credit-card",
                            to: "/investing-entities",
                            permissions: [Permissions.VIEW_INVESTING_ENTITES],
                        },
                        {
                            label: "Distributions",
                            icon: "pi pi-fw pi-globe",
                            to: "/distributions",
                            permissions: [Permissions.VIEW_DISTRIBUTIONS],
                        },
                        {
                            label: "Investments",
                            icon: "pi pi-fw pi-box",
                            to: "/investments",
                            permissions: [Permissions.VIEW_INVESTMENTS],
                        },
                        {
                            label: "Payment Accounts",
                            icon: "pi pi-fw pi-briefcase",
                            to: "/payment-accounts",
                            permissions: [Permissions.VIEW_PAYMENT_ACCOUNTS],
                        },
                    ],
                },
            ],
            home: { icon: "pi pi-home", to: "/" },
        };
    },
    mounted() {
        this.readyMenuItems();
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        readyMenuItems() {
            this.menu = [
                ...this.menu,
                ...(this.$can(Permissions.VIEW_USERS) ||
                this.$can(Permissions.VIEW_USERS)
                    ? [
                        {
                            label: "Admin",
                            items: [
                                {
                                    label: "Users",
                                    icon: "pi pi-fw pi-user",
                                    to: "/users",
                                    permissions: [Permissions.VIEW_USERS],
                                },
                                {
                                    label: "Views",
                                    icon: "pi pi-eye",
                                    to: "/views",
                                    permissions: [Permissions.VIEW_VIEWS],
                                },
                            ],
                        },
                    ]
                    : []),
            ];
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === "overlay") {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === "static") {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className);
            else element.className += " " + className;
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className);
            else
                element.className = element.className.replace(
                    new RegExp(
                        "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                        "gi"
                    ),
                    " "
                );
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === "static")
                    return !this.staticMenuInactive;
                else if (this.layoutMode === "overlay")
                    return this.overlayMenuActive;
            }

            return true;
        },
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive":
                        this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active":
                        this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                    "p-input-filled":
                        this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": this.$primevue.config.ripple === false,
                },
            ];
        },
        logo() {
            return this.s3_url + "public-uploads/10xinvestlogo.jpg";
        },
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, "body-overflow-hidden");
        else this.removeClass(document.body, "body-overflow-hidden");
    },
    components: {
        AppTopBar: AppTopBar,
        AppMenu: AppMenu,
        AppConfig: AppConfig,
    },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>