import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import { Permissions } from "./shared/constants";
import store from "./store";

function checkAuth(to, from, next) {
    if (store.getters["auth/hasUser"]) {
        if(!to.meta.permissions){
            next();
        }
        if (store.getters["auth/canAccess"](to.meta.permissions)) {
            next();
        } else {
            next("/restricted");
        }
    } else next("/login");
}

function checkGuest(to, from, next) {
    if (store.getters["auth/hasUser"]) next("/");
    else next();
}

const routes = [
    {
        path: "/",
        name: "app",
        component: App,
        children: [
            {
                path: "/",
                redirect: () => {
                    return { path: "/contacts" };
                },
            },
            {
                path: "/contacts",
                name: "contacts",
                meta: {
                    permissions: [Permissions.VIEW_CONTACTS],
                    breadcrumb: [
                        {
                            label: "Contacts",
                            to: "/contacts"
                        }
                    ]
                },
                beforeEnter: checkAuth,
                component: () => import("./pages/ContactsPage.vue"),
            },
            {
                path: "/investing-entities",
                name: "investing_entities",
                meta: {
                    permissions: [Permissions.VIEW_INVESTING_ENTITES],
                    breadcrumb: [
                        {
                            label: "Investing entities",
                            to: "/investin-entities"
                        }
                    ]
                },
                beforeEnter: checkAuth,
                component: () => import("./pages/InvestingEntitiesPage.vue"),
            },
            {
                path: "/distributions",
                name: "distributions",
                meta: {
                    permissions: [Permissions.VIEW_DISTRIBUTIONS],
                    breadcrumb: [
                        {
                            label: "Distributions",
                            to: "/distributions"
                        }
                    ]
                },
                beforeEnter: checkAuth,
                component: () => import("./pages/DistributionsPage.vue"),
            },
            {
                path: "/investments",
                name: "investments",
                meta: {
                    permissions: [Permissions.VIEW_INVESTMENTS],
                    breadcrumb: [
                        {
                            label: "Investments",
                            to: "/investments"
                        }
                    ]
                },
                beforeEnter: checkAuth,
                component: () => import("./pages/InvestmentsPage.vue"),
            },
            {
                path: "/payment-accounts",
                name: "payment_accounts",
                beforeEnter: checkAuth,
                meta: {
                    permissions: [Permissions.VIEW_PAYMENT_ACCOUNTS],
                    breadcrumb: [
                        {
                            label: "Payment accounts",
                            to: "/payment-accounts"
                        }
                    ]
                },
                component: () => import("./pages/PaymentAccountsPage.vue"),
            },
            {
                path: "/users",
                name: "users",
                beforeEnter: checkAuth,
                meta: {
                    permissions: [Permissions.VIEW_USERS],
                    breadcrumb: [
                        {
                            label: "Users",
                            to: "/users"
                        }
                    ]
                },
                component: () => import("./pages/UsersPage.vue"),
            },
            {
                path: "/views",
                name: "views",
                beforeEnter: checkAuth,
                meta: {
                    permissions: [Permissions.VIEW_VIEWS],
                    breadcrumb: [
                        {
                            label: "Views",
                            to: "/views"
                        }
                    ]
                },
                component: () => import("./pages/Views/ViewsPage.vue"),
            },
            {
                path: "/audit-log/:type",
                name: "audit_log",
                beforeEnter: checkAuth,
                meta: {
                    permissions: [Permissions.VIEW_LOGS],
                    breadcrumb: [
                        {
                            label: "Audit Log",
                        }
                    ]
                },
                component: () => import("./pages/LogPage.vue"),
            },
            {
                path: "/404",
                component: () => import("./pages/404.vue"),
                beforeEnter: checkAuth,
            },
            {
                path: "/restricted",
                component: () => import("./pages/403.vue"),
            },
            { path: "/:pathMatch(.*)*", redirect: "/404" },
        ],
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: checkGuest,
        component: () => import("./pages/Login.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
