import { googleTokenLogin } from "vue3-google-login";
const axios = require("axios");

export default class AuthService {
    async login() {
        const googleToken = await googleTokenLogin();
        if(googleToken){
            let res = await axios.post("/auth/google/callback", googleToken);
            localStorage.setItem("token", res?.data.data?.token);
            res = await this.fetchUser({params: { include: "permissions" }});
            return res;
        }
    }

    async logout({ params = {}, data, ...rest } = {}) {
        const response = await axios.post("/auth/logout", data, {
            params,
            ...rest,
        });
        return response;
    }

    async fetchUser({ params = {} }) {
        const response = await axios.get("auth/user-profile", { params });
        return response;
    }
}
