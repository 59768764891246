import * as TYPES from"./types";

export default {
    [TYPES.FETCH_USER_SUCCESS](state, payload) {
        state.user = payload;
    },
    [TYPES.FETCH_USER_FAILURE](state) {
        state.user = null;
    },
    [TYPES.LOGIN_START](state) {
        state.isAuthenticating = true;
    },
    [TYPES.LOGIN_SUCCESS](state, payload) {
        state.user = payload;
        state.isAuthenticating = false;
    },
    [TYPES.LOGOUT](state) {
        state.user = null;
    },
    [TYPES.LOGIN_FAILURE](state) {
        state.error = "Something went wrong";
        state.isAuthenticating = false;
    },
};
