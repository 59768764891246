import { hasPermission } from "../../../shared/utils";

export default {
    user: (auth) => auth.user,
    hasUser: (auth) => !!auth.user,
    isAuthenticating: (auth) => auth.isAuthenticating,
    permissions: (auth) => auth.user.permissions,
    canAccess: (auth) => (requiredPermissions) => {
        if(auth.user?.permissions){
            return hasPermission(requiredPermissions, 
                auth.user.permissions.map(permission => permission.name));
        }
        return false;
    }
};
