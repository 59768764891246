<template>
    <div class="layout-menu-container">
        <div class="mb-4 menu-header-text">HOME</div>
        <AppSubmenu
            :items="model"
            class="layout-menu ml-3"
            :root="true"
            @menuitem-click="onMenuItemClick"
            @keydown="onKeyDown" />
        <!-- <a href="https://www.primefaces.org/primeblocks-vue" class="block mt-3">
			<img alt="primeblocks" :src="bannerImage()" class="w-full" />
		</a> -->
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
    props: {
        model: Array
    },
    methods: {
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
        onKeyDown(event) {
            const nodeElement = event.target;
            if (event.code === "Enter" || event.code === "Space") {
                nodeElement.click();
                event.preventDefault();
            }
        },
        bannerImage() {
            return this.$appState.darkTheme ? "images/banner-primeblocks-dark.png" : "images/banner-primeblocks.png";
        }
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    },
    components: {
        "AppSubmenu": AppSubmenu
    }
};
</script>
