<script>
import { includes, castArray } from "lodash-es";

export default {
    methods: {
        $can(name) {
            let permissions = this.$me ? this.$me.permissions : [];
            if (typeof name == Array) {
                var intersections = permissions.filter(
                    (e) => name.indexOf(e) !== -1
                );
                return intersections.length > 0 ? true : false;
            } else {
                name = castArray(name);
                return Boolean(permissions.find((p) => includes(name, p.name)));
            }
        },
    },
};
</script>
