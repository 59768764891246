<script>
import store from "../store";

export default {
    computed: {
        $me() {
            return store.getters["auth/user"];
        },
    },
};
</script>
