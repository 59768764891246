import axios from "axios";
import app from "../src/main";

axios.defaults.timeout = process.env.VUE_APP_AXIOS_DEFAULT_TIMEOUT;

axios.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
            "token"
        )}`;
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        let toastAble = response?.config?.toastAble;
        if (toastAble) {
            app.config.globalProperties.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: response?.data?.message,
                life: 3000,
            });
        }
        return response;
    },
    (error) => {
        if (
            error?.response?.status === 422 ||
            error?.response?.status === 400
        ) {
            const errorsMessage = error?.response?.data?.errors;
            Object.keys(errorsMessage).forEach((key) => {
                app.config.globalProperties.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: errorsMessage[key][0],
                    life: 3000,
                });
            });
        } else {
            if (error?.response?.status === 401) {
                app.config.globalProperties.$router.push({ name: "login" });
            } else {
                app.config.globalProperties.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: "API Error on server side",
                    life: 3000,
                });
            }
        }
        return false;
    }
);
